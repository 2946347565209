
.container {
    /* max-width: 640px; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
}

ul.ks-cboxtags {
    list-style: none;
}
ul.ks-cboxtags li{
  display: inline;
}
ul.ks-cboxtags li label{
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    border: 2px solid #3ac67b;
    color: black;
    font: bold;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
}

ul.ks-cboxtags li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: "\f067";
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
    color: black;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    border: 2px solid black;
    background-color: #3ac67b;
    color: #101010;
    transition: all .2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 2px solid #1f46b2;
}

.my_container {
  max-width: 640px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

ul.my_ks-cboxtags {
  list-style: none;
  padding: 20px;
}
ul.my_ks-cboxtags li{
display: inline;
}
ul.my_ks-cboxtags li label{
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}

ul.my_ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.my_ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

ul.my_ks-cboxtags li input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
  color: black;
}

ul.my_ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid #1bdbf8;
  background-color: #12bbd4;
  color: #101010;
  transition: all .2s;
}

ul.my_ks-cboxtags li input[type="checkbox"] {
display: absolute;
}
ul.my_ks-cboxtags li input[type="checkbox"] {
position: absolute;
opacity: 0;
}
ul.my_ks-cboxtags li input[type="checkbox"]:focus + label {
border: 2px solid #aca1ff;
}

