@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  width: 100%;
  min-height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  /* background: linear-gradient(140deg, #230B4D 2%, #8B216A 80%); */
  background-size: cover;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Login_Div {
  min-height: 100vh;
}

.TableDropdown {
  position: relative;
  display: inline-block;
}

.DivDropDown {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px;
  z-index: 1;
  font-size: smaller;
}

.DivDropDown div {
  margin-bottom: 2px;
}

.TableDropdown:hover .DivDropDown {
  display: block;
}

.react-datepicker__portal, .react-datepicker-popper {
  z-index: 20;
}



.custom-radio {
  display: none;
}

.custom-radio-label {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #f442bc;
  position: relative;
  cursor: pointer;
}

.custom-radio-label::after {
  content: "";
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: #f442bc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-radio:checked + .custom-radio-label::after {
  opacity: 1;
}